<template>
  <NoteHeader :show-delete="false" />
  <div class="profile__note note">
    <form class="profile__note-form" @submit.prevent="onSubmit">
      <input
        v-model="note.title"
        class="profile__note-title"
        placeholder="Type title"
        required
      />
      <textarea
        class="profile__note-text"
        placeholder="Type something here"
        v-model="note.content"
        required
      ></textarea>
      <div class="login__input-wrapper input-wrapper input-wrapper--login">
        <button type="submit">
          <span class="text">Create</span>
        </button>
      </div>
    </form>
    <!-- <button class="btn-reset note__delete">
      <img
        src="@/static/profile/notes/delete.svg"
        alt="Edit note"
        title="Delete note"
      />
    </button> -->
  </div>
</template>

<script>
import { notification } from 'ant-design-vue'
import { createNote } from '@/services/note.service'
import NoteHeader from './NoteHeader.vue'

export default {
  name: 'NoteCreate',
  components: { NoteHeader },
  data() {
    return {
      note: {},
    }
  },
  methods: {
    createNote,
    onSubmit() {
      this.createNote(this.note)
        .then(() => {
          notification.success({
            message: 'Successfully created',
          })
          this.$router.push({ name: 'Notes' })
        })
        .catch((err) => {
          // notification.error({
          //   message: err.message,
          // })
        })
    },
  },
}
</script>
<style lang="scss">
.note {
  max-width: 662px;
  margin-right: auto;
  margin-left: auto;
  color: var(--color-white);
  position: relative;

  &__delete {
    position: absolute;
    top: 10px;
    right: 0;
  }
}

.profile {
  &__note-form {
    display: grid;
    row-gap: 39px;
  }
  &__note-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    background: inherit;
    border: none;
    outline: none;

    &::placeholder {
      color: rgba(#fff, 0.5);
    }
  }
  &__note-text {
    background: inherit;
    border: none;
    outline: none;

    &::placeholder {
      color: rgba(#fff, 0.5);
    }
  }
}
</style>
